// 全局请求路径
window._LOCALHOST = "http://localhost:8082/carbon"; //101.42.117.62:8082
//上传路径
window._UPLOADHOST = "http://39.105.147.41:8082/carbon"

//单点登陆平台域名
window._LOGIN = "http://www.ccai.com.cn/#/login/"

//单点登陆平台域名
window._HANGER = "http://www.ccai.com.cn/#/hanger/"

//单点登陆平台域名
window._PAY = "http://www.ccai.com.cn/#/product/buy"
