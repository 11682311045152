//动态路由
export const asyncRouterMap = [
    {
        path: '/dashboard',
        name: '智能驾驶舱',
        component: () => import('@/views/home/DashboardView.vue'),
        meta: { title: '智能驾驶舱', topMenu: '/home', leftMenu: [1, '/home'] },
    },
    {
        path: 'htmlview',
        name: '/htmlview',
        component: () => import('@/views/home/HtmlView.vue'),
        meta: { title: '消息显示', topMenu: '/home', leftMenu: [1, '/home'] },
    },
    {
        path: '/tabulation',
        name: 'tabulation',
        component: () => import('@/views/home/TabulationView.vue'),
        meta: { title: '咨讯列表', topMenu: '/home', leftMenu: [1, '/home'] },
    },
    {
        path: '/',
        name: '首页',
        component: () => import('@/views/layout/LayoutView.vue'),
        meta: { title: '首页' },
        children: [
            {
                path: 'home',
                name: '/home',
                component: () => import('@/views/home/IndexView.vue'),
                meta: { title: '数据概览', topMenu: '/home', leftMenu: [1, '/home'] },
            },
            {
                path: '/expertlist',
                name: '/expertlist',
                component: () => import('@/views/home/ExpertList.vue'),
                meta: { title: '专家列表', topMenu: '/home', leftMenu: [1, '/home'] },
            },
        ]
    },
    {
        path: '/emission',
        name: '碳排放',
        component: () => import('@/views/layout/LayoutView.vue'),
        meta: { title: '碳排放' },
        children: [
            {
                path: 'ReportingView',
                name: '/emission/ReportingView',
                component: () => import('@/views/emission/ReportingView.vue'),
                meta: { title: '排放数据填报', topMenu: '/emission/ReportingView', leftMenu: [2, '/emission/ReportingView'] },
            },
            {
                path: 'ReportingEditView',
                name: '/emission/ReportingEditView',
                component: () => import('@/views/emission/ReportingEditView.vue'),
                meta: { title: '填报记录操作', topMenu: '/emission/ReportingView', leftMenu: [2, '/emission/ReportingView'] },
            },
            {
                path: 'ReportingLookView',
                name: '/emission/ReportingLookView',
                component: () => import('@/views/emission/ReportingLookView.vue'),
                meta: { title: '填报记录查看', topMenu: '/emission/ReportingView', leftMenu: [2, '/emission/ReportingView'] },
            },
            {
                path: 'ReportingSettingView',
                name: '/emission/ReportingSettingView',
                component: () => import('@/views/emission/ReportingSettingView.vue'),
                meta: { title: '填报流程设置', topMenu: '/emission/ReportingView', leftMenu: [2, '/emission/ReportingSettingEditView'] },
            },
            {
                path: 'ReportingSettingEditView',
                name: '/emission/ReportingSettingEditView',
                component: () => import('@/views/emission/ReportingSettingEditView.vue'),
                meta: { title: '填报流程设置', topMenu: '/emission/ReportingView', leftMenu: [2, '/emission/ReportingSettingEditView'] },
            },
            {
                path: 'ReportingItemView',
                name: '/emission/ReportingItemView',
                component: () => import('@/views/emission/ReportingItemView.vue'),
                meta: { title: '填报项管理', topMenu: '/emission/ReportingView', leftMenu: [2, '/emission/ReportingItemView'] },
            },
            {
                path: 'ReportingItemEditView',
                name: '/emission/ReportingItemEditView',
                component: () => import('@/views/emission/ReportingItemEditView.vue'),
                meta: { title: '填报项编辑', topMenu: '/emission/ReportingView', leftMenu: [2, '/emission/ReportingItemView'] },
            },
            {
                path: 'ReportingItemFactorView',
                name: '/emission/ReportingItemFactorView',
                component: () => import('@/views/emission/ReportingItemFactorView.vue'),
                meta: { title: '因子库管理', topMenu: '/emission/ReportingView', leftMenu: [2, '/emission/ReportingItemFactorView'] },
            },
            {
                path: 'ReportingItemFactorEditView',
                name: '/emission/ReportingItemFactorEditView',
                component: () => import('@/views/emission/ReportingItemFactorEditView.vue'),
                meta: { title: '操作', topMenu: '/emission/ReportingView', leftMenu: [2, '/emission/ReportingItemFactorView'] },
            },
            {
                path: 'ReportingCertifiedView',
                name: '/emission/ReportingCertifiedView',
                component: () => import('@/views/emission/ReportingCertifiedView.vue'),
                meta: { title: '填报记录核证核证', topMenu: '/emission/ReportingView', leftMenu: [2, ''] },
            }
        ]
    },
    {
        path: '/property',
        name: '碳资产',
        component: () => import('@/views/layout/LayoutView.vue'),
        meta: { title: '碳资产' },
        children: [
            {
                path: 'IndexView',
                name: '/property/IndexView',
                component: () => import('@/views/property/IndexView.vue'),
                meta: { title: '碳资产总览', topMenu: '/property/IndexView', leftMenu: [3, '/property/IndexView'] },
            },
            {
                path: 'promise/DealView',
                name: '/property/promise/DealView',
                component: () => import('@/views/property/promise/DealView.vue'),
                meta: { title: '履约交易记录', topMenu: '/property/IndexView', leftMenu: [3, '/property/promise/DealView'] },
            },
            {
                path: 'promise/DealEditView',
                name: '/property/promise/DealEditView',
                component: () => import('@/views/property/promise/DealEditView.vue'),
                meta: { title: '操作', topMenu: '/property/IndexView', leftMenu: [3, '/property/promise/DealView'] },
            },
            {
                path: 'promise/CarbonEmissionView',
                name: '/property/promise/CarbonEmissionView',
                component: () => import('@/views/property/promise/CarbonEmissionView.vue'),
                meta: { title: '碳排放报告', topMenu: '/property/IndexView', leftMenu: [3, '/property/promise/CarbonEmissionView'] },
            },
            {
                path: 'promise/CertificationView',
                name: '/property/promise/CertificationView',
                component: () => import('@/views/property/CertificationView.vue'),
                meta: { title: '碳资产核证记录', topMenu: '/property/IndexView', leftMenu: [3, '/property/promise/CertificationView'] },
            },
            {
                path: 'promise/CertificationCertifiedView',
                name: '/property/promise/CertificationCertifiedView',
                component: () => import('@/views/property/promise/CertificationCertifiedView.vue'),
                meta: { title: '碳资产核证记录核证', topMenu: '/property/IndexView', leftMenu: [3, '/property/promise/CertificationView'] },
            },
            {
                path: 'promise/CertificationEditView',
                name: '/property/promise/CertificationEditView',
                component: () => import('@/views/property/promise/CertificationEditView.vue'),
                meta: { title: '操作', topMenu: '/property/IndexView', leftMenu: [3, '/property/promise/CertificationView'] },
            },
            {
                path: 'QuotaView',
                name: '/property/QuotaView',
                component: () => import('@/views/property/QuotaView.vue'),
                meta: { title: '碳配额管理', topMenu: '/property/IndexView', leftMenu: [3, '/property/QuotaView'] },
            },
            {
                path: 'QuotaEditView',
                name: '/property/promise/QuotaEditView',
                component: () => import('@/views/property/promise/QuotaEditView.vue'),
                meta: { title: '操作', topMenu: '/property/IndexView', leftMenu: [3, '/property/QuotaView'] },
            },
            {
                path: 'QuotaMonthView',
                name: '/property/QuotaMonthView',
                component: () => import('@/views/property/QuotaMonthView.vue'),
                meta: { title: '月度详情', topMenu: '/property/IndexView', leftMenu: [3, '/property/QuotaView'] },
            },
            {
                path: 'CarbonNeutralView',
                name: '/property/CarbonNeutralView',
                component: () => import('@/views/property/CarbonNeutralView.vue'),
                meta: { title: '碳中和管理', topMenu: '/property/IndexView', leftMenu: [3, '/property/CarbonNeutralView'] },
            },
            {
                path: 'ccer/CcerCertifiedView',
                name: '/property/ccer/CcerCertifiedView',
                component: () => import('@/views/property/CcerCertifiedView.vue'),
                meta: { title: 'CCER自愿减排核证', topMenu: '/property/IndexView', leftMenu: [3, '/property/CcerView'] },
            },
            {
                path: 'ccer/CcerProcessView',
                name: '/property/ccer/CcerProcessView',
                component: () => import('@/views/property/CcerProcessView.vue'),
                meta: { title: 'CCER自愿减排审核', topMenu: '/property/IndexView', leftMenu: [3, '/property/CcerView'] },
            },
            {
                path: 'CcerView',
                name: '/property/CcerView',
                component: () => import('@/views/property/CcerView.vue'),
                meta: { title: 'CCER自愿减排', topMenu: '/property/IndexView', leftMenu: [3, '/property/CcerView'] },
            },
            {
                path: 'CcerCalcView',
                name: '/property/CcerCalcView',
                component: () => import('@/views/property/CcerCalcView.vue'),
                meta: { title: 'CCER测算', topMenu: '/property/IndexView', leftMenu: [3, '/property/CcerView'] },
            },
            {
                path: 'CcerDetailView',
                name: '/property/CcerDetailView',
                component: () => import('@/views/property/CcerDetailView.vue'),
                meta: { title: 'CCER详情查看', topMenu: '/property/IndexView', leftMenu: [3, '/property/CcerView'] },
            },
            {
                path: 'CcerAddView',
                name: '/property/CcerAddView',
                component: () => import('@/views/property/CcerAddView.vue'),
                meta: { title: 'CCER新增', topMenu: '/property/IndexView', leftMenu: [3, '/property/CcerView'] },
            },
            {
                path: 'CcerContrastView',
                name: '/property/CcerContrastView',
                component: () => import('@/views/property/CcerContrastView.vue'),
                meta: { title: 'CCER对比分析', topMenu: '/property/IndexView', leftMenu: [3, '/property/CcerView'] },
            },
        ]
    },
    {
        path: '/trade',
        name: '碳交易',
        component: () => import('@/views/layout/LayoutView.vue'),
        meta: { title: '碳交易' },
        children: [
            {
                path: 'IndexView',
                name: '/trade/IndexView',
                component: () => import('@/views/trade/IndexView.vue'),
                meta: { title: '交易市场', topMenu: '/trade/IndexView', leftMenu: [4, '/trade/IndexView'] },
            },
            {
                path: 'OrderNeedView',
                name: '/trade/OrderNeedView',
                component: () => import('@/views/trade/OrderNeedView.vue'),
                meta: { title: '供需大厅', topMenu: '/trade/IndexView', leftMenu: [4, '/trade/OrderNeedView'] },
            },
            {
                path: 'OrderNeedEditView',
                name: '/trade/OrderNeedEditView',
                component: () => import('@/views/trade/OrderNeedEditView.vue'),
                meta: { title: '发布需求', topMenu: '/trade/IndexView', leftMenu: [4, '/trade/OrderNeedView'] },
            },
            {
                path: 'OrderNeedDetail',
                name: '/trade/OrderNeedDetail',
                component: () => import('@/views/trade/OrderNeedDetail.vue'),
                meta: { title: '供需详情', topMenu: '/trade/IndexView', leftMenu: [4, '/trade/OrderNeedView'] },
            }
        ]
    },
    {
        path: '/finance',
        name: '碳金融',
        component: () => import('@/views/layout/LayoutView.vue'),
        meta: { title: '碳金融' },
        children: [
            {
                path: 'KnowledgeView',
                name: '/finance/KnowledgeView',
                component: () => import('@/views/finance/KnowledgeView.vue'),
                meta: { title: '碳金融知识库', topMenu: '/finance/ProductView', leftMenu: [5, '/finance/KnowledgeView'] },
            },
            {
                path: 'KnowledgeEditView',
                name: '/finance/KnowledgeEditView',
                component: () => import('@/views/finance/KnowledgeEditView.vue'),
                meta: { title: '操作', topMenu: '/finance/ProductView', leftMenu: [5, '/finance/KnowledgeView'] },
            },
            {
                path: 'PolicyView',
                name: '/finance/PolicyView',
                component: () => import('@/views/finance/PolicyView.vue'),
                meta: { title: '碳金融政策库', topMenu: '/finance/ProductView', leftMenu: [5, '/finance/PolicyView'] },
            },
            {
                path: 'PolicyEditView',
                name: '/finance/PolicyEditView',
                component: () => import('@/views/finance/PolicyEditView.vue'),
                meta: { title: '操作', topMenu: '/finance/ProductView', leftMenu: [5, '/finance/PolicyView'] },
            },
            {
                path: 'ProductView',
                name: '/finance/ProductView',
                component: () => import('@/views/finance/ProductView.vue'),
                meta: { title: '碳金融产品信息', topMenu: '/finance/ProductView', leftMenu: [5, '/finance/ProductView'] },
            },
            {
                path: 'ProductDetailView',
                name: '/finance/ProductDetailView',
                component: () => import('@/views/finance/ProductDetailView.vue'),
                meta: { title: '查看', topMenu: '/finance/ProductView', leftMenu: [5, '/finance/ProductView'] },
            }
        ]
    },
    {
        path: '/system',
        name: '系统管理',
        component: () => import('@/views/layout/LayoutView.vue'),
        meta: { title: '系统管理' },
        children: [
            {
                path: 'InBoxView',
                name: '/system/InBoxView',
                component: () => import('@/views/system/InBoxView.vue'),
                meta: { title: '收件箱', topMenu: '/system/InBoxView', leftMenu: [19, '/system/InBoxView'] },
            },
            {
                path: 'CompanyView',
                name: '/system/CompanyView',
                component: () => import('@/views/system/CompanyView.vue'),
                meta: { title: '机构管理', topMenu: '/system/InBoxView', leftMenu: [19, '/system/CompanyView'] },
            },
            {
                path: 'CompanyCertView',
                name: '/system/CompanyCertView',
                component: () => import('@/views/system/CompanyCertView.vue'),
                meta: { title: '企业认证', topMenu: '/system/InBoxView', leftMenu: [19, '/system/CompanyCertView'] },
            },
            {
                path: 'CompanyEditView',
                name: '/system/CompanyEditView',
                component: () => import('@/views/system/CompanyEditView.vue'),
                meta: { title: '操作', topMenu: '/system/InBoxView', leftMenu: [19, '/system/CompanyView'] },
            },
            {
                path: 'CompanyCertView',
                name: '/system/CompanyCertView',
                component: () => import('@/views/system/CompanyCertView.vue'),
                meta: { title: '企业认证', topMenu: '/system/InBoxView', leftMenu: [19, '/system/CompanyCertView'] },
            },
            {
                path: 'UserView',
                name: '/system/UserView',
                component: () => import('@/views/system/UserView.vue'),
                meta: { title: '用户管理', topMenu: '/system/InBoxView', leftMenu: [19, '/system/UserView'] },
            },
            {
                path: 'UserEditView',
                name: '/system/UserEditView',
                component: () => import('@/views/system/UserEditView.vue'),
                meta: { title: '操作', topMenu: '/system/InBoxView', leftMenu: [19, '/system/UserView'] },
            },
            {
                path: 'SysRoleView',
                name: '/system/SysRoleView',
                component: () => import('@/views/system/SysRoleView.vue'),
                meta: { title: '角色管理', topMenu: '/system/InBoxView', leftMenu: [19, '/system/SysRoleView'] },
            },
            {
                path: 'SysRoleEditView',
                name: '/system/SysRoleEditView',
                component: () => import('@/views/system/SysRoleEditView'),
                meta: { title: '操作', topMenu: '/system/InBoxView', leftMenu: [19, '/system/SysRoleView'] },
            },
            {
                path: 'SysPermView',
                name: '/system/SysPermView',
                component: () => import('@/views/system/SysPermView.vue'),
                meta: { title: '菜单管理', topMenu: '/system/InBoxView', leftMenu: [6, '/system/SysPermView'] },
            },
            {
                path: 'SysPermEditView',
                name: '/system/SysPermEditView',
                component: () => import('@/views/system/SysPermEditView.vue'),
                meta: { title: '菜单管理', topMenu: '/system/InBoxView', leftMenu: [19, '/system/SysPermView'] },
            },
            {
                path: 'UserSettingView',
                name: '/system/UserSettingView',
                component: () => import('@/views/system/UserSettingView.vue'),
                meta: { title: '账号设置', topMenu: '/system/InBoxView', leftMenu: [19, ''] },
            },
            {
                path: 'UserPhoneEditView',
                name: '/system/UserPhoneEditView',
                component: () => import('@/views/system/UserPhoneEditView.vue'),
                meta: { title: '账号设置', topMenu: '/system/InBoxView', leftMenu: [19, '/system/UserPhoneEditView'] },
            },
            {
                path: 'WarningView',
                name: '/system/WarningView',
                component: () => import('@/views/system/WarningView.vue'),
                meta: { title: '预警信息', topMenu: '/system/InBoxView', leftMenu: [19, ''] },
            },
            {
                path: 'ConfigView',
                name: '/system/ConfigView',
                component: () => import('@/views/system/ConfigView.vue'),
                meta: { title: '系统配置', topMenu: '/system/InBoxView', leftMenu: [19, '/system/ConfigView'] },
            },
            {
                path: 'NewsView',
                name: '/system/NewsView',
                component: () => import('@/views/system/NewsView.vue'),
                meta: { title: '消息公告管理', topMenu: '/system/InBoxView', leftMenu: [19, '/system/NewsView'] },
            },
            {
                path: 'NewsEditView',
                name: '/system/NewsEditView',
                component: () => import('@/views/system/NewsEditView.vue'),
                meta: { title: '消息公告编辑', topMenu: '/system/InBoxView', leftMenu: [19, '/system/NewsEditView'] },
            },
            {
                path: 'ReportingLogView',
                name: '/system/ReportingLogView',
                component: () => import('@/views/emission/ReportingLogView.vue'),
                meta: { title: '填报日志', topMenu: '/system/InBoxView', leftMenu: [19, '/system/ReportingLogView'] },
            },
        ]
    },
]
