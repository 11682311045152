import axios from 'axios'
import { Message, MessageBox } from 'element-ui'
import {getToken, removeToken} from '@/utils/auth'

// 创建axios实例
const service = axios.create({
  //baseURL: 'http://39.105.147.41:8082/carbon',
  baseURL: 'http://120.26.107.237:8082/carbon',
  //  baseURL: 'http://localhost:8082/carbon',//
  timeout: 60000 // 请求超时时间
})

// request拦截器
service.interceptors.request.use(config => {
  config.headers['accessToken'] = getToken() === undefined ? '' : getToken(); // 让每个请求携带自定义token 请根据实际情况自行修改
  config.headers['Cache-Control'] = 'no-cache';
      config.headers['Pragma'] = 'no-cache';
  return config
}, error => {
  // Do something with request error
  console.log(error) // for debug
  Promise.reject(error)
})

// respone拦截器
service.interceptors.response.use(
  response => {
  /**
  * code为非200是抛错 可结合自己业务进行修改
  */
    // 导出
    if(response.headers['content-type'].indexOf('application/json') == -1){
        return response
    }
    const res = response.data
    if (res.code !== 0) {
      Message({
        message: res.message,
        showClose: true,
        type: 'error',
        duration: 3 * 1000
      })

      // 401:未登录;
      if (res.code === 403) {
        console.log(res.code)
        MessageBox.confirm('你已被登出，可以取消继续留在该页面，或者重新登录', '确定登出', {
          confirmButtonText: '重新登录',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          removeToken();
          location.reload()// 为了重新实例化vue-router对象 避免bug
        })
      }else if(res.code === 510){
          removeToken();
          setTimeout(() => {
              //next({ path: '/login', query: {  } })
              location.reload()// 为了重新实例化vue-router对象 避免bug
          }, 3 * 1000);
      }
      return Promise.reject('error')
    } else {
      return response.data
    }
  },
  error => {
    // 403:未登录;
    if (error.response.data.code === 403) {
      MessageBox.confirm('你已被登出，可以取消继续留在该页面，或者重新登录', '确定登出', {
        confirmButtonText: '重新登录',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        removeToken();
        let t = window.location.href;
        let len = t.indexOf('?') !== -1 ? t.indexOf('?') : t.length;
        window.location.href = window._LOGIN + '?targetUrl=' + encodeURIComponent(t.substr(0, len).replace('hanger','home'));
      })
      return Promise.reject('error');
    }else{
      Message({
        message: error.response.data.message,
        type: 'error',
        showClose: true,
        duration: 3 * 1000
      })
      return Promise.reject(error)
    }
  }
)

export default service
